import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/SEO';
import SectionIntro from '../../components/SectionIntro';
import '../../templates/blog-post-content.css';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {graphql, useStaticQuery} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';

const LegalPage = ({data}) => {
  const dataImages = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top-right.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);

  const {backgroundImage} = dataImages;

  const bgImage = convertToBgImage(getImage(backgroundImage));

  return (
    <Layout seo={<Seo title="Terms of Service | Trustle" description="Legal Documents and Terms of Service" />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <div className="max-w-[42rem] mx-auto pl-5 md:pl-0">
          <section className="pt-16 pb-8 mb-8 ">
            <div className="max-w-5xl px-5 pt-0 mx-auto text-center text-white">
              <SectionIntro title="Legal" isPageHeader />
            </div>
          </section>
        </div>

        <div className="mx-auto max-w-[42rem] pb-48 from-cms">
          <h2>Legal Documents</h2>
          <ul>
            <li>
              <a href="/legal/tosDocument ">Trustle Terms of Service</a>
            </li>
            <li>
              <a href="/legal/privacyDocument">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </BackgroundImage>
    </Layout>
  );
};

export default LegalPage;
